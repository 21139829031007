<template>
  <div class="page">
    <div class="banner">
      <img src="./img/listbanner.png" alt="" />
    </div>
    <div class="main-title">
      {{ dateStr }}<br />红领巾专线下午班车报名人数查看
    </div>
    <div class="busShift" @click="show = true">
      <div class="title">车辆班次</div>
      <div class="banci">{{ batchTime || "请选择班次" }}</div>
      <img src="./img/down-arrow.png" alt="" />
    </div>
    <div class="tab">
      <div class="item">学生姓名</div>
      <div class="item">所在班级</div>
      <div class="item">联系家长</div>
      <div class="item">当日签到</div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="name">{{ item.childrenName }}</div>
        <div class="class">{{ item.childrenClass }}</div>
        <div class="img">
          <img
            v-if="item.parentsPhone"
            src="./img/tel.png"
            @click="calling(item)"
          />
          <div v-else>临时添加</div>
        </div>
        <div class="btn">
          <div
            class="removeBtn"
            v-if="item.parentsPhone == ''"
            @click="removeItem(item)"
          >
            删除
          </div>
          <div class="noSgin" v-else-if="item.id == ''">当天不乘坐</div>
          <div
            class="sginBtn"
            v-else-if="item.signStatus == 0 && item.id"
            @click="signRightNow(item)"
          >
            签到
          </div>
          <div class="sginedBtn" v-else-if="item.signStatus == 1 && item.id">
            已签到
          </div>
        </div>
      </div>
    </div>
    <!-- 新增按钮 -->
    <div class="addBtn" @click="addInfo">新增</div>
    <v-picker
      :columns="busFrequencyList"
      :valueShow="show"
      valueKey="batchTime"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import {
  delRecordsByIdUrl,
  getSignInfoUrl,
  changeSignUrl,
  getBusFrequencyUrl,
} from "./api.js";
export default {
  name: "studySignList",
  data() {
    return {
      show: false,
      dateStr: "",
      list: [],
      userInfo: {},
      busFrequencyList: [],
      batchId: null,
      batchTime: null,
      frequencyId: null,
    };
  },
  async created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log(this.userInfo);
    this.getDateStr();
  },
  async mounted() {
    await this.getBusFrequencyList();
    await this.getList();
  },
  methods: {
    addInfo() {
      this.$router.push({
        name: "addStudySignInfo",
      });
    },
    async getBusFrequencyList() {
      let tenantId = this.userInfo.tenantId;
      let res = await this.$axios.get(
        `${getBusFrequencyUrl}?tenantId=${tenantId}`,
        {
          isQinxue: true,
          headers: {
            "hc-auth": "bearer " + this.userInfo.accessToken,
            Authorization: "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
          },
        }
      );
      if (res.code == 200) {
        this.busFrequencyList = [];
        res.data.forEach((item) => {
          if (item.batchTime != "当天不乘车") {
            this.busFrequencyList.push(item);
          }
        });
        this.batchId = res.data[0].batchId;
        this.batchTime = res.data[0].batchTime;
        this.frequencyId = res.data[0].frequencyId;
      }
    },
    removeItem(item) {
      let params = {
        recordsId: item.id + "",
      };
      this.$axios
        .post(`${delRecordsByIdUrl}`, this.$qs.stringify(params), {
          isQinxue: true,
          headers: {
            "hc-auth": "bearer " + this.userInfo.accessToken,
            Authorization: "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast("操作成功！");
            setTimeout(() => {
              this.getList();
            }, 400);
          }
        });
    },
    calling(item) {
      window.location.href = `tel://${item.parentsPhone}`;
    },
    signRightNow(item) {
      let params = {
        id: item.id,
        signStatus: 1,
        userId: this.userInfo.userId,
      };
      this.$axios
        .post(`${changeSignUrl}`, this.$qs.stringify(params), {
          isQinxue: true,
          headers: {
            "hc-auth": "bearer " + this.userInfo.accessToken,
            Authorization: "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast("签到成功~");
            this.getList();
          }
        });
    },
    getDateStr() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      this.dateStr = `${year}年${month}月${day}日`;
    },
    async getList() {
      let tenantId = this.userInfo.tenantId;
      let res = await this.$axios.get(
        `${getSignInfoUrl}?tenantId=${tenantId}&batchId=${this.batchId}&busFrequency=${this.frequencyId}`,
        {
          isQinxue: true,
          headers: {
            "hc-auth": "bearer " + this.userInfo.accessToken,
            Authorization: "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
          },
        }
      );
      if (res.code == 200) {
        this.list = res.data;
      }
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      this.batchId = value.batchId;
      this.batchTime = value.batchTime;
      this.frequencyId = value.frequencyId;
      this.show = false;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  padding: 36px 30px 160px;
  box-sizing: border-box;
  .addBtn {
    width: 400px;
    height: 80px;
    background: #007eff;
    border-radius: 48px;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
    line-height: 80px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
  }
  .banner {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    img {
      width: 100%;
    }
  }
  .main-title {
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    padding-bottom: 22px;
    line-height: 44px;
  }
  .busShift {
    width: 100%;
    height: 88px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
    border-radius: 8px;
    margin-bottom: 22px;
    padding: 0 28px 0 34px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    line-height: 88px;
    .title {
      flex: 1;
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .banci {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 17px;
      height: 10px;
      margin-left: 12px;
    }
  }
  .tab {
    width: 100%;
    height: 88px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
    border-radius: 8px;
    display: flex;
    align-content: center;
    line-height: 88px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 26px;
    .item {
      flex: 1;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .list {
    width: 100%;
    min-height: 1036px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
    border-radius: 8px;
    padding: 32px 0;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 60px;
      margin-bottom: 36px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      .name,
      .class {
        flex: 1;
        text-align: center;
      }
      .img {
        flex: 1;
        div {
          text-align: center;
          color: #e02020;
        }
        img {
          margin: 0 auto;
          display: block;
          width: 34px;
        }
      }
      .btn {
        flex: 1;
        .noSgin {
          margin: 0 auto;
          width: 140px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          font-size: 24px;
          color: #e02020;
        }
        .removeBtn {
          margin: 0 auto;
          width: 140px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          background: #e02020;
          border-radius: 48px;
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
        }
        .sginBtn {
          margin: 0 auto;
          width: 140px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          background: #007eff;
          border-radius: 48px;
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
        }
        .sginedBtn {
          margin: 0 auto;
          width: 140px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 48px;
          border: 2px solid #007eff;
          font-size: 24px;
          font-weight: 600;
          color: #007eff;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
